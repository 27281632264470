@font-face {
  font-family: roboto-regular;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: roboto-light;
  src: url('./assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: roboto-bold;
  src: url('./assets/fonts/Roboto-Bold.ttf');
}
