@font-face {
  font-family: roboto-regular;
  src: url('/fonts/Roboto-Regular.ttf');
}

ul.header li {
  display: inline;
  list-style-type: none;
  font-family: Roboto-Regular;
  font-size: 1.2em;
}

ul.header li a {
  color: #fff;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
  font-size: 10px;
}

@media (max-width: 1200px) {
  .header {
    display: none;
  }
}
